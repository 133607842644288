import React from 'react';
import { graphql } from 'gatsby';
import { makeStyles } from '@material-ui/styles';
import { Paper } from '@material-ui/core';

import Imgix from 'react-imgix';
import Layout from '../components/Layout';
import SearchEngineOptimization from '../components/SearchEngineOptimization';


const useStyles = makeStyles((theme) => ({
  hero: {
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(2),
    width: `100%`
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.4)',
    zIndex: 5,
  },
  heroFigure: {
    maxHeight: "100%",
    height: "200px",
    margin: 0,
    zIndex: 1,
  },
  heroFigureImg: {
    width: "100%",
    height: "200px",
    objectFit: "cover",
  },
  heroCatch: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: "auto auto 10%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    color: "#fff",
    textShadow: "0 0 20px rgba(0,0,0,0.5)",
    zIndex: 1000,
  },
  blockTitle: {
    marginTop: `1rem`
  }
}))

const Index = (({ data }) => {
  const classes = useStyles()
  return (
    <Layout
      siteMetadata={data.allMicrocmsSite.edges[0].node}
      categories={[]}
      hideCategory={true}>
      <SearchEngineOptimization />
      <Paper className={classes.hero}>
        <div className={classes.overlay} />
        <figure className={classes.heroFigure}>
          <Imgix className={classes.heroFigureImg}
            src={data.allMicrocmsSite.edges[0].node.hero.url}
            htmlAttributes={{
              alt: `${data.allMicrocmsSite.edges[0].node.title} メイン画像`
            }}
          />
        </figure>
      </Paper>
      <div className="postbody">
        お探しのページはみつかりませんでした。 / Page Not Found.
      </div>
    </Layout>
  )
})

export const query = graphql`
query {
  allMicrocmsSite {
    edges {
      node {
        id
        hero {
          url
          height
          width
        }
        title
        desc
        siteUrl
        lang
        locale
      }
    }
  }
}
`

export default Index